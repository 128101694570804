<template lang="pug">
  #post-call-thanks.interrupt-step-component
    icon#kit-logo(data="@icon/kit-logo.svg" color="#3F3F3F #151515")

    icon#agent-icon(data="@icon/person-waving.svg")

    h2 Thank you for using Kit!

    button.button(@click="gotoNext()") Continue
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    gotoNext,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function gotoNext() {
  this.$emit('complete')
}
</script>
